<template>
  <div class="emb-product-detail">
    <template>
      <v-container grid-list-xl>
        <v-card class="my-6">
          <div class="text-h4 ma-6 pt-3 font-weight-bold">
            {{ $t("message.freePage.header") }}
          </div>

          <div class="text-subtitle-1 mx-6">
            {{ $t("message.freePage.subHeader") }}
          </div>
          <div class="emb-productDetail-content white">
            <v-btn
              v-show="isAdmin"
              color="accent"
              dark
              small
              absolute
              right
              fab
              @click="addFreeTicket()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <div class="related-product section-gap">
              <v-container grid-list-xl class="py-0">
                <div class="product-listing" v-if="freeLotteries.length != 0">
                  <v-layout row wrap mb-4>
                    <template v-for="lottery of freeLotteries">
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg3
                        xl3
                        mb3
                        text-center
                        :key="lottery.id"
                        v-if="
                          isAdmin || lottery.display_date.toDate() <= new Date()
                        "
                      >
                        <div
                          class="emb-card card-size clickable"
                          @click="viewFreeLottery(lottery.id)"
                        >
                          <div class="thumb-wrap">
                            <div class="pa-3 image-container">
                              <img
                                :src="
                                  lotteries_info.lotteries[lottery.type_name]
                                    .image
                                "
                                alt="related product"
                              />
                            </div>

                            <!-- <div class="add-to-cart">
                          <v-btn
                            @click="viewFreeLottery(lottery.id)"
                            class="primary"
                            small
                            icon
                          >
                            <v-icon medium>mdi-magnify</v-icon>
                          </v-btn>
                        </div> -->
                          </div>
                          <div
                            class="emb-card-content px-6 py-4 white"
                            v-if="general_info != null"
                          >
                            <div class="emb-meta-info">
                              <div
                                class="
                                  layout
                                  align-center
                                  justify-space-between
                                  pa-4
                                "
                              >
                                <div class="inline-block">
                                  <h5 class="black--text font-weight-medium">
                                    {{
                                      $formatMoneyToWord(
                                        general_info.lotteries[
                                          lottery.type_name
                                        ].next_draw.amount,
                                        "US$",
                                        ""
                                      )
                                    }}
                                  </h5>
                                  <div class="inline-block">
                                    <h6
                                      class="
                                        grey--text
                                        justify-center
                                        align-center
                                      "
                                    >
                                      <emb-countdown
                                        :deadline="lottery.draw_date.toDate()"
                                      >
                                      </emb-countdown>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div
                                block
                                class="green white--text py-1 rounded-sm"
                                v-if="
                                  freeLotteriesSubscriptions[lottery.id] !=
                                  undefined
                                "
                              >
                                {{ $t("message.freePage.subscribed") }}
                              </div>
                              <div block class="py-1 rounded-sm" v-else>
                                <v-btn outlined>{{
                                  $t("message.freePage.enterHere")
                                }}</v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </template>
                  </v-layout>
                </div>
                <div v-else>{{ $t("message.freePage.noTicket") }}</div>
              </v-container>
            </div>
          </div>
        </v-card>
      </v-container>
    </template>
  </div>
</template>

<style scoped>
.card-size {
  width: 220px;
  height: 250px;
}
.image-container {
  height: 120px;
}
.clickable {
  cursor: pointer;
}
.sub-header {
  max-width: 700px;
  width: 90%;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "isAdmin",
      "lotteries_info",
      "freeLotteries",
      "general_info",
      "getUser",
      "freeLotteriesSubscriptions",
    ]),
  },
  data() {
    return {};
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != null) {
        this.$store.dispatch("fetch_free_subscriptions");
      }
    },
  },
  methods: {
    viewFreeLottery(ticket_id) {
      this.$router.push({
        path: "playFreeLottery/" + ticket_id,
      });
    },
    addFreeTicket() {
      this.$router.push({
        //path: "admin-panel/camera/",
        name: "Camera",
        params: { type: "free" },
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("fetch_free_lotteries");
    this.$store.dispatch("fetch_free_subscriptions");
  },
};
</script>
