var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"emb-product-detail"},[[_c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-card',{staticClass:"my-6"},[_c('div',{staticClass:"text-h4 ma-6 pt-3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("message.freePage.header"))+" ")]),_c('div',{staticClass:"text-subtitle-1 mx-6"},[_vm._v(" "+_vm._s(_vm.$t("message.freePage.subHeader"))+" ")]),_c('div',{staticClass:"emb-productDetail-content white"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAdmin),expression:"isAdmin"}],attrs:{"color":"accent","dark":"","small":"","absolute":"","right":"","fab":""},on:{"click":function($event){return _vm.addFreeTicket()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('div',{staticClass:"related-product section-gap"},[_c('v-container',{staticClass:"py-0",attrs:{"grid-list-xl":""}},[(_vm.freeLotteries.length != 0)?_c('div',{staticClass:"product-listing"},[_c('v-layout',{attrs:{"row":"","wrap":"","mb-4":""}},[_vm._l((_vm.freeLotteries),function(lottery){return [(
                        _vm.isAdmin || lottery.display_date.toDate() <= new Date()
                      )?_c('v-flex',{key:lottery.id,attrs:{"xs12":"","sm6":"","md6":"","lg3":"","xl3":"","mb3":"","text-center":""}},[_c('div',{staticClass:"emb-card card-size clickable",on:{"click":function($event){return _vm.viewFreeLottery(lottery.id)}}},[_c('div',{staticClass:"thumb-wrap"},[_c('div',{staticClass:"pa-3 image-container"},[_c('img',{attrs:{"src":_vm.lotteries_info.lotteries[lottery.type_name]
                                  .image,"alt":"related product"}})])]),(_vm.general_info != null)?_c('div',{staticClass:"emb-card-content px-6 py-4 white"},[_c('div',{staticClass:"emb-meta-info"},[_c('div',{staticClass:"layout align-center justify-space-between pa-4"},[_c('div',{staticClass:"inline-block"},[_c('h5',{staticClass:"black--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$formatMoneyToWord( _vm.general_info.lotteries[ lottery.type_name ].next_draw.amount, "US$", "" ))+" ")]),_c('div',{staticClass:"inline-block"},[_c('h6',{staticClass:"grey--text justify-center align-center"},[_c('emb-countdown',{attrs:{"deadline":lottery.draw_date.toDate()}})],1)])])]),(
                                _vm.freeLotteriesSubscriptions[lottery.id] !=
                                undefined
                              )?_c('div',{staticClass:"green white--text py-1 rounded-sm",attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.$t("message.freePage.subscribed"))+" ")]):_c('div',{staticClass:"py-1 rounded-sm",attrs:{"block":""}},[_c('v-btn',{attrs:{"outlined":""}},[_vm._v(_vm._s(_vm.$t("message.freePage.enterHere")))])],1)])]):_vm._e()])]):_vm._e()]})],2)],1):_c('div',[_vm._v(_vm._s(_vm.$t("message.freePage.noTicket")))])])],1)],1)])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }